(function ($) {
  Drupal.behaviors.gnavSubmenuLinkV1 = {
    attached: false,
    attach: function (context) {
      var $module = $('.js-gnav-submenu-link-v1', context);
      var $accordionTrigger = $('.js-gnav-submenu-link__title-accordion', $module);
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isMobile = parseInt(bp.width, 10) < parseInt(bps['usn-medium-max'], 10);

      if (this.attached) {
        return;
      }
      this.attached = true;

      function setAccordionEvent() {
        $accordionTrigger.once().off('click.submenu').on('click.submenu', function () {
          $accordionTrigger.not(this).find('.gnav-submenu-link__input-label').prop('checked', false);
        });
      }

      if (isMobile) {
        setAccordionEvent();
      }
      Unison.on('change', function (currentBp) {
        isMobile = parseInt(currentBp.width, 10) < parseInt(bps['usn-medium-max'], 10);
        if (isMobile) {
          setAccordionEvent();
        }
      });
    }
  };
})(jQuery);
